import appIcon from './assets/app_icon.png';
import screenshot1 from './assets/screenshots/1.png';
import screenshot2 from './assets/screenshots/2.png';
import screenshot3 from './assets/screenshots/3.png';
import screenshot4 from './assets/screenshots/4.png';
import screenshot5 from './assets/screenshots/5.png';
import './App.css';

// Google Play and App Store icons
const googlePlayIcon = 'https://cdn-icons-png.flaticon.com/512/732/732208.png';
const appStoreIcon = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/App_Store_%28iOS%29.svg/2048px-App_Store_%28iOS%29.svg.png';

function App() {
  // Function to handle the Google Play button click
  const handleGooglePlayClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.ug.picquiz", "_blank");
  };

  // Function to handle the App Store button click
  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/us/app/pic-2-word-ai-pic-puzzle-quiz/id6736536012", "_blank");
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white">
      {/* Header Section */}
      <header className="flex flex-col items-center py-12 bg-gray-800 shadow-lg">
        <img src={appIcon} className="w-32 mb-4 transform transition-transform duration-500 hover:scale-110" alt="App Icon" />
        <h1 className="text-5xl font-extrabold text-primary tracking-wide drop-shadow-md">
          Pic 2 Word: AI Pic Puzzle Quiz
        </h1>
        <p className="text-lg mt-4 text-gray-300 italic">
          The Ultimate Witty Picture Puzzle Experience!
        </p>
        {/* Download Buttons */}
        <div className="mt-6 flex space-x-4">
          <button 
            onClick={handleGooglePlayClick} 
            className="bg-gradient-to-r from-purple-500 to-pink-500 text-white py-3 px-6 rounded-full font-semibold shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 flex items-center space-x-2"
          >
            <img src={googlePlayIcon} alt="Google Play Icon" className="w-6 h-6" />
            <span>Download on Google Play</span>
          </button>
          <button 
            onClick={handleAppStoreClick} 
            className="bg-gradient-to-r from-blue-500 to-green-500 text-white py-3 px-6 rounded-full font-semibold shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 flex items-center space-x-2"
          >
            <img src={appStoreIcon} alt="App Store Icon" className="w-6 h-6" />
            <span>Download on the App Store</span>
          </button>
        </div>
      </header>

      {/* About Section */}
      <section className="p-12 text-center">
        <h2 className="text-4xl font-semibold text-primary mb-6">
          About the Game
        </h2>
        <p className="text-lg leading-relaxed text-gray-300">
          Step into the world of clever puzzles where every picture hides a tricky word! 
          In <strong>Pic 2 Word</strong>, you’ll be presented with AI-generated images, 
          each more witty than the last. Your mission? Crack the code and guess the word! 
          Some words are easy to spot, but others will make you think twice. 
          Thankfully, you can use hints to nudge you in the right direction.
        </p>
        <p className="text-lg mt-4 leading-relaxed text-gray-300">
          No two levels are the same, so you'll face a mix of fun and challenging puzzles 
          to keep things exciting. Are you ready to have fun and challenge your brain?
        </p>
      </section>

      {/* Why You'll Love Section */}
      <section className="p-12 bg-gray-800 text-center">
        <h2 className="text-4xl font-semibold text-primary mb-6">
          Why You'll Love Pic 2 Word:
        </h2>
        <ul className="space-y-4">
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">🧠 Witty AI-Generated Pictures to Puzzle Over</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">🎮 Challenging and Tricky Word Puzzles</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">💡 Use Hints to Help You Solve the Toughest Words</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">📅 Daily Challenges for Even More Fun</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">🌍 Compete on Global Leaderboards</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">🎨 Cute, Creative, and Fun Visuals</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">👨‍👩‍👧‍👦 Fun for All Ages</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">🔤 Expand Your Vocabulary with Every Puzzle</li>
          <li className="text-lg transform transition-transform duration-300 hover:scale-105">📶 Play Offline Anytime, Anywhere</li>
        </ul>
      </section>

      {/* Game Screenshots Section */}
      <section className="p-12 text-center">
        <h2 className="text-4xl font-semibold text-primary mb-6">Game Screenshots</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 p-4">
          {/* Screenshot 1 */}
          <div className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105">
            <img src={screenshot1} alt="Screenshot 1" className="w-full h-auto" />
            <div className="p-4">
              <p className="text-gray-300">Crack the clever AI-generated picture puzzle!</p>
            </div>
          </div>
          {/* Screenshot 2 */}
          <div className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105">
            <img src={screenshot2} alt="Screenshot 2" className="w-full h-auto" />
            <div className="p-4">
              <p className="text-gray-300">Use hints if you’re stuck – they can save the day!</p>
            </div>
          </div>
          {/* Screenshot 3 */}
          <div className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105">
            <img src={screenshot3} alt="Screenshot 3" className="w-full h-auto" />
            <div className="p-4">
              <p className="text-gray-300">Tricky puzzles keep the game fresh and exciting.</p>
            </div>
          </div>
          {/* Screenshot 4 */}
          <div className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105">
            <img src={screenshot4} alt="Screenshot 4" className="w-full h-auto" />
            <div className="p-4">
              <p className="text-gray-300">Levels vary in difficulty, no two are the same!</p>
            </div>
          </div>
          {/* Screenshot 5 */}
          <div className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transform transition-transform hover:scale-105">
            <img src={screenshot5} alt="Screenshot 5" className="w-full h-auto" />
            <div className="p-4">
              <p className="text-gray-300">Enjoy the challenge and have a blast solving puzzles!</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gray-800 text-center py-6">
        <p className="text-gray-500">&copy; 2024 Pic 2 Word. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
